import ocb from '../../../assets/image/ocb/demo.png'
import { formatMoney, upperCase, convertDateToString3 } from '../../../utils/utils'

const OCB = (props: any) => {


  return (
    <div className="ocb">
      <div className="ocb__image">
        <img src={ocb} alt="" />
      </div>
      <div className="ocb__form">

        <div className='time'>{props.data.time}</div>

        <div className='money'>{formatMoney(props.data.money, 0, 0)} VND</div>
        <div className='name_person_account'>{props.data.number_person_account}</div>
        <div className='name_person_account1'>{upperCase(props.data.name_person_account)}</div>

        <div className='name_account'>{props.data.number_account}</div>
        <div className='name_person_account2'>{upperCase(props.data.name_account)}</div>
        <div className='tag_bank'>{props.data.bank}</div>

        <div className='date'>{props.data.time} {convertDateToString3(props.data.date)}</div>

        <div className='trading_code'>{props.data.trading_code}</div>
        <div className='content'>{props.data.content}</div>

      </div>
    </div>
  )
}

export default OCB
