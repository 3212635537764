import header from '../../../assets/image/acb/header.png'
import border from '../../../assets/image/acb/border.png'
import { formatMoney2, upperCase, convertDateToString } from '../../../utils/utils'

const ACB = (props: any) => {


  return (
    <div className="acb">
      <div className='acb__header'>
        <div>
          <div className="acb__header__time"><span>{props.data.time}</span></div>
          <img src={header} alt="" />
        </div>
      </div>
      <div className='acb__header2'>
        <div className='acb__header2__money1'> {formatMoney2(props.data.money, 0, 0)} VND</div>
        <div className='acb__header2__money2'> {
          props.data.money_text +
          ' đồng'
        }</div>
        <div className='acb__header2__bnt_sc'>Giao dịch thành công</div>
        <div className='acb__header2__date_start1'>
          <div>Ngày nhập lệnh</div>
          <div>
            {convertDateToString(props.data.date)} - {props.data.time}:00
          </div>
        </div>
        <div className='acb__header2__date_start1'>
          <div>Ngày hiệu lực</div>
          <div>
            {convertDateToString(props.data.date)}
          </div>
        </div>

      </div>
      <img src={border} alt="" />
      <div className='acb__body'>
        <div className='acb-title'>
          <p></p>
          Bên chuyển
        </div>

        <div className='acb__body__transfer'>
          <div>{upperCase(props.data.name_person_account)}</div>
          <div>{props.data.number_person_account}</div>
        </div>
        <div className='acb-title'>
          <p></p>
          Bên nhận
        </div>
        <div className='acb__body__item'>
          <div>Tên người nhận</div>
          <div>{upperCase(props.data.name_account)}</div>
        </div>
        <div className='acb__body__item'>
          <div>Ngân hàng nhận</div>
          <div>{props.data.bank}</div>
        </div>
        <div style={{marginBottom: "15px"}} className='acb__body__item'>
          <div>Tài khoản nhận</div>
          <div>{props.data.number_account}</div>
        </div>



        <div className='acb__body__border'>

        </div>
        <div className='acb-title'>
          <p></p>
          Thông tin giao dịch
        </div>
        <div className='acb__body__item'>
          <div>Thời gian</div>
          <div>Chuyển ngay</div>
        </div>
        <div className='acb-crollbar'></div>
      </div>
    </div>
  )
}

export default ACB
