import img1 from '../../../assets/image/vtb/bg-2.png'
import img2 from '../../../assets/image/vtb/favicon.png'
import img3 from '../../../assets/image/vtb/time.png'
import img4 from '../../../assets/image/vtb/footvtb.png'
import {
  formatMoney,
  upperCase,
  convertDateToString,  
} from '../../../utils/utils'

const Vietin = (props: any) => {
  return (
    <div className="vtb">
      <div
        className="v-head"
        style={{
          background: `url('${img3}')`
        }}
      >
        <div className="v-time">
          {props.data.time?.length === 4
            ? `0${props.data.time}`
            : props.data.time}
        </div>
      </div>
      <img
        className="bg-img"
        src={img1}
        alt=""
        style={{
          width: 340
        }}
      />
      <div className="content">
        <img
          src={img2}
          alt=""
          style={{
            width: 178,
            background: '#fefefe',
            opacity: 0.1
          }}
        />
      </div>
      <div className="date-time">
        <div className="label">{`${convertDateToString(props.data.date)} ${
          props.data.time?.length === 4
            ? `0${props.data.time}`
            : props.data.time
        }`}</div>
        <div className="label">{props.data.trading_code}</div>
      </div>
      <div className="info">
        <div className="v-row">
          <span className="left">Từ tài khoản</span>
          <span className="right">
            <div>********{props.data.number_person_account?.slice(-4)}</div>
            <div
              className="m-name"
              style={{
                fontWeight: 400
              }}
            >
              {upperCase(props.data.name_person_account)}
            </div>
          </span>
        </div>
        <div className="v-row">
          <span className="left">Đến tài khoản</span>
          <span className="right">
            <div className="m-name">{props.data.number_account}</div>
            <div className="m-name">{upperCase(props.data.name_account)}</div>
          </span>
        </div>
        <div className="v-row">
          <span className="left">Ngân hàng</span>
          <span className="right">{props.data.bank}</span>
        </div>
        <span className="v-row">
          <div className="left">Số Tiền</div>
          <div className="right v-money">
            {formatMoney(props.data.money, 0, 0)} VND
            <br />
            {
              props.data.money_text +
              'Đồng'
            }
          </div>
        </span>
        <div className="v-row">
          <span className="left">Phí</span>
          <span className="right">
            {
              // Viết hàm switch phí chuyển tuyền theo ngân hàng
              'Miễn phí'
            }
          </span>
        </div>
        <div className="v-row">
          <span className="left">Nội dung</span>
          <span
            className="right"
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              wordBreak: 'normal',
              boxSizing: 'border-box',
              overflow: 'hidden'
            }}
          >
            {props.data.content}
          </span>
        </div>
        <div className="foot-img">
          <img src={img4} alt="" className="v-f-img" />
        </div>
      </div>
      <div className="cen-s"></div>
      <div className="cen-2"></div>
    </div>
  )
}

export default Vietin
