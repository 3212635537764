import img1 from '../../../assets/image/mb/1.png'
import img2 from '../../../assets/image/mb/pin.png'
import img3 from '../../../assets/image/mb/tick.png'
import img4 from '../../../assets/image/mb/share.png'
import img5 from '../../../assets/image/mb/save-btn.png'
import img6 from '../../../assets/image/mb/draft-btn.png'
import foot from '../../../assets/image/mb/foot.png'
import {
  formatMoney,
  upperCase,
  convertDateToString,
  dataBank
} from '../../../utils/utils'

const MB = (props: any) => {
  let logo = dataBank.find(function (element) {
    return element.id === props.data.bank_logo;
  });

  return (
    <div className="mb">
      <div className="bg-img">
        <div className="b-header">
          <div className="b-header__time">{props.data.time}</div>
          <div className="b-header__pin">
            <img src={img2} alt="" />
          </div>
        </div>
        <img src={img1} alt="" className="img" />
        <div className="mb_bank_success">
          <img
            src={img3}
            alt=""
            style={{
              width: '42px',
              height: '40px',
              borderRadius: '40px'
            }}
          />
          <div className="bank_success__title">
            Bạn đã chuyển tiền thành công
          </div>
          <div className="bank_success__money">
            {formatMoney(props.data.money, 0, 0)} VND
          </div>
          <div className="bank_success__share">
            <img
              src={img4}
              alt=""
              style={{
                width: '108px',
                borderRadius: '40px'
              }}
            />
          </div>
          <div className="bank_to_info">
            <div className="card">
              <div className="title">Đến tài khoản</div>
              <div className="body">
                {/** day prop icon theo ngan hang thu huong */}
                <div className="icon">
                  <img
                    src={logo?.img}
                    alt=""
                    style={{
                      width: '28px',
                      boxShadow: '',
                      borderRadius: '40px'
                    }}
                  />
                </div>
                <div className="account">
                  <div className="name_account">
                    {upperCase(props.data.name_account)}
                  </div>
                  <div className="font-x">{props.data.number_account}</div>
                  <div className="font-x">{logo?.name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transaction-info">
        <div className="card">
          <div className="m-row">
            <span className="left">Tài khoản nguồn</span>
            <span className="right">
              <div>{props.data.number_person_account}</div>
              <div className="m-name">{upperCase(props.data.name_person_account)}</div>
            </span>
          </div>
          <div className="m-row">
            <span className="left">Nội dung</span>
            <span className="right">{props.data.content}</span>
          </div>
          <span className="m-row">
            <div className="left">Thời gian</div>
            <div className="right">
              {`${props.data.time}:41, ${convertDateToString(props.data.date)}`}
            </div>
          </span>
          <div className="m-row">
            <span className="left">Hình thức chuyển</span>
            <span className="right">Chuyển nhanh Napas 247</span>
          </div>
          <div className="m-row">
            <span className="left">Mã giao dịch</span>
            <span className="right">{props.data.trading_code} </span>
          </div>
        </div>
        <div className="btn-list">
          <div className="btn-row">
            <span>
              <img
                src={img5}
                alt=""
                style={{
                  width: '100%'
                }}
              />
            </span>
            <span>
              <img
                src={img6}
                alt=""
                style={{
                  width: '100%'
                }}
              />
            </span>
          </div>
          <div className="btn-row">
            <span className="m-btn">Về trang chủ</span>
            <span
              className="m-btn"
              style={{
                background: '#7c8b97',
                color: '#fff'
              }}
            >
              Tạo giao dịch khác
            </span>
          </div>
        </div>
        <div className="m-footer">
          <span>
            <img
              src={foot}
              alt=""
              style={{
                width: '100%'
              }}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export default MB
