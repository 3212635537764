import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function AuthenticatedGuard() {
    const authenticated = localStorage.getItem('login')
    if (!authenticated) return <Navigate to="/login" />
    return (
        <>
            <Outlet />
        </>
    )
}

export default AuthenticatedGuard
