import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './css/style.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Invoices from './pages/Invoices'
import Expenses from './pages/Expenses'
import NotFound from './pages/NotFound'
import LoginPage from './pages/LoginPage';
import AuthenticatedGuard from './pages/AuthenticatedGuard';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>

        <Route path="/login" element={<LoginPage />}></Route>
        <Route element={<AuthenticatedGuard />}>
          <Route path="/" element={<App />}>
            <Route path="invoices" element={<Invoices />}>
            </Route>


            <Route path="expenses" element={<Expenses />} />
            {/* No other routes match */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
