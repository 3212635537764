
import { Link, Outlet } from 'react-router-dom'
import './App.css'

function App() {
  return (
    <div className="App">
      <h1>AUTO BILL</h1>
      <nav
        style={{
          borderBottom: '1px solid',
          paddingBottom: '1rem'
        }}
      >
        <Link to="invoices">Chuyển Khoản</Link> |{' '}
        <Link to="expenses">Số Dư</Link>
      </nav>
      <Outlet />
    </div>
  )
}

export default App
