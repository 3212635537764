import agribank from '../../../assets/image/agribank/demo.png'
import { formatMoney, upperCase, convertDateToString } from '../../../utils/utils'

const Agribank = (props: any) => {


  return (
    <div className="agribank">
      <div className="agribank__image">
        <img src={agribank} alt="" />
      </div>
      <div className="agribank__form">
          <div className='time'>{props.data.time}</div>
          <div className='money'>{formatMoney(props.data.money, 0, 0)} VND</div>
          <div className='tag_bank'>{props.data.number_account}</div>
          <div className='name_account'>{upperCase(props.data.name_account)}</div>
          <div className='trading_code'>{props.data.trading_code}</div>
          <div className='bank'>{props.data.bank}</div>
          <div className='free'>0 VND</div>
          <div className='date'>{props.data.time} {convertDateToString(props.data.date)}</div>
      </div>
    </div>
  )
}

export default Agribank
