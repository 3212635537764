import vcb from '../assets/image/vcb/logo.png'
import vietcombank from '../assets/image/logo/vietcombank.png'
import agribank from '../assets/image/logo/agribank.png'
import vietinbank from '../assets/image/logo/vietinbank.png'
import mbbank from '../assets/image/logo/mbbank.png'
import techcombank from '../assets/image/logo/techcombank.png'
import abbank from '../assets/image/logo/abbank.png'
import acb from '../assets/image/logo/acb.png'
import anz from '../assets/image/logo/anz.png'
import bacabank from '../assets/image/logo/bacabank.jpg'
import baovietbank from '../assets/image/logo/baovietbank.jpg'
import cake from '../assets/image/logo/cake.png'
import donga from '../assets/image/logo/donga.jpg'
import eximbank from '../assets/image/logo/eximbank.png'
import hdbank from '../assets/image/logo/hdbank.png'
import hsbc from '../assets/image/logo/hsbc.png'
import kienlong from '../assets/image/logo/kienlong.jpg'
import lienviet from '../assets/image/logo/lienviet.png'
import msb from '../assets/image/logo/msb.png'
import ncb from '../assets/image/logo/ncb.jpg'
import namabank from '../assets/image/logo/namabank.jpg'
import ocb from '../assets/image/logo/ocb.png'
import oceanbank from '../assets/image/logo/oceanbank.png'
import pvcombank from '../assets/image/logo/pvcombank.jpg'
import scb from '../assets/image/logo/scb.jpg'
import shb from '../assets/image/logo/shb.png'
import saigonbank from '../assets/image/logo/saigonbank.png'
import seabank from '../assets/image/logo/seabank.jpg'
import shinhan from '../assets/image/logo/shinhan.jpg'
import tpbank from '../assets/image/logo/tpbank.png'
import vib from '../assets/image/logo/vib.png'
import vpbank from '../assets/image/logo/vpbank.png'
import vietcap from '../assets/image/logo/vietcap.png'
import wooribank from '../assets/image/logo/wooribank.jpg'
import bidv from '../assets/image/logo/bidv.png'
import sacombank from '../assets/image/logo/sacombank.png'
import vietabank from '../assets/image/logo/vietabank.png'


export const formatMoney = (mumber, n, x) => {
    if (mumber) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
        return mumber.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
    }
}

export const formatMoney2 = (mumber, n, x) => {
    if (mumber) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
        return mumber.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.')
    }
}

export const formatMoney3 = (mumber, n, x) => {
    if (mumber) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
        return mumber.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ')
    }
}

export const upperCase = text => {
    if (text) {
        return text.toUpperCase()
    }
}

export const getIcon = bank => {
    if (bank === 'vcb') {
        return vcb
    }
}

export const LayThu = date => {
    if (date) {
        var a = new Date(date)
        var b = a.getDay()
        switch (b) {
            case 0:
                return 'Chủ Nhật'
            case 1:
                return 'Thứ Hai'
            case 2:
                return 'Thứ Ba'
            case 3:
                return 'Thứ Tư'
            case 4:
                return 'Thứ Năm'
            case 5:
                return 'Thứ Sáu'
            case 6:
                return 'Thứ Bảy'
            default:
                break
        }
        return
    }
}

export const convertDateToString = date => {
    if (!date) return ''
    // eslint-disable-next-line no-redeclare
    var date = new Date(date)
    if (typeof date === 'string') return date
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return day + '/' + month + '/' + date.getFullYear()
}


export const convertDateToString3 = date => {
    if (!date) return ''
    // eslint-disable-next-line no-redeclare
    var date = new Date(date)
    if (typeof date === 'string') return date
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return day + '.' + month + '.' + date.getFullYear()
}

export const getHours = date => {
    if (!date) return ''
    // eslint-disable-next-line no-redeclare
    var date = new Date(date)
    if (typeof date === 'string') return date
    let hour = date.getHours()
    let mi = date.getMinutes()
    if (mi < 10) {
        mi = '0' + mi
    }
    return hour + ':' + mi
}

export const convertDateToString2 = date => {
    if (!date) return ''
    var date = new Date(date)
    if (typeof date === 'string') return date
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return date.getFullYear() + '-' + month + '-' + day
}

export const genZipcode = bank => {
    var text = ''
    var possible = ''

    // eslint-disable-next-line default-case
    switch (parseInt(bank)) {
        case 1:
            possible = '0123456789'
            for (var i = 1; i <= 7; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = '407' + text
            break

        case 2:
            possible = '0123456789ABCDEFGHIJKLMNOP0123456789QRSTUVWXYZ0123456789'
            for (var i = 1; i <= 12; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            break
        case 3:
            possible = '0123456789'
            for (var i = 1; i <= 10; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = 'FT2322' + text
            break

        case 4:
            possible = '0123456789'
            for (var i = 1; i <= 10; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = 'FT2322' + text
            break
        case 5:
            possible = '0123456789'
            // eslint-disable-next-line no-redeclare
            for (var i = 1; i <= 7; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = '407' + text
            break

        case 6:
            possible = '0123456789ABCDEFGHIJKLMNOP0123456789QRSTUVWXYZ0123456789'
            for (var i = 1; i <= 1; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = '932S' + text
            break
        case 7:
            possible = '0123456789'
            for (var i = 1; i <= 6; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            break

        case 8:
            possible = '0123456789'
            for (var i = 1; i <= 6; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            break
        case 9:
            possible = '0123456789'
            for (var i = 1; i <= 7; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = '407' + text
            break

        case 10:
            possible = '0123456789'
            for (var i = 1; i <= 9; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            break
        case 11:
            possible = '0123456789'
            for (var i = 1; i <= 7; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = '407' + text
            break

        case 12:
            possible = '0123456789ABCDEFGHIJKLMNOP0123456789QRSTUVWXYZ0123456789'
            for (var i = 1; i <= 7; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            text = 'FT232' + text
            break
    }

    return text
}



export const dataBank = [
    {
        id: "183",
        img: vietcombank,
        name: "Ngoại thương Việt Nam (VIETCOMBANK, VCB)"
    },
    {
        id: "184",
        img: agribank,
        name: "Nông nghiệp và Phát triển Nông thôn VN (AGRIBANK)"
    },
    {
        id: "185",
        img: vietinbank,
        name: "Công Thương Việt Nam (VietinBank)"
    },
    {
        id: "186",
        img: mbbank,
        name: "Quân đội (MB)"
    },
    {
        id: "187",
        img: techcombank,
        name: "Kỹ Thương Việt Nam (TCB)"
    },
    {
        id: "188",
        img: abbank,
        name: "An Bình (AB)"
    },
    {
        id: "189",
        img: acb,
        name: "Ngân hàng Á Châu"
    },
    {
        id: "190",
        img: anz,
        name: "Ngân hàng TNHH một thành viên ANZ Việt Nam"
    },
    {
        id: "191",
        img: bacabank,
        name: "Ngân hàng TMCP Bắc Á"
    },
    {
        id: "192",
        img: baovietbank,
        name: "Bảo Việt"
    },
    {
        id: "193",
        img: cake,
        name: ""
    },
    {
        id: "194",
        img: donga,
        name: "Ngân hàng TMCP Đông Á"
    },
    {
        id: "195",
        img: eximbank,
        name: "Ngân hàng xuất nhập khẩu Việt Nam"
    },

    {
        id: "196",
        img: hdbank,
        name: "NH TMCP Phát triển Nhà Tp HC"
    },
    {
        id: "197",
        img: hsbc,
        name: "Ngân hàng TNHH một thành viên HSBC Việt Nam"
    },
    {
        id: "198",
        img: kienlong,
        name: "Kiên Long"
    },
    {
        id: "199",
        img: lienviet,
        name: "Bưu điện Liên Việt"
    },
    {
        id: "200",
        img: msb,
        name: "Hàng Hải Việt Nam"
    },
    {
        id: "201",
        img: ncb,
        name: "Quốc Dân"
    },
    {
        id: "202",
        img: namabank,
        name: "Ngân hàng TMCP Nam Á"
    },
    {
        id: "203",
        img: ocb,
        name: "Phương Đông"
    },
    {
        id: "204",
        img: oceanbank,
        name: "Ngân hàng Đại Dương"
    },
    {
        id: "205",
        img: pvcombank,
        name: "Đại chúng Việt Nam"
    },
    {
        id: "206",
        img: scb,
        name: "Ngân hàng TMCP Sài Gòn"
    },

    {
        id: "207",
        img: shb,
        name: "Sài Gòn-Hà Nội"
    },
    {
        id: "208",
        img: saigonbank,
        name: "Sài Gòn Công Thương"
    },
    {
        id: "209",
        img: seabank,
        name: "Ngân hàng Đông Nam Á"
    },
    {
        id: "210",
        img: shinhan,
        name: "Ngân hàng TNHH MTV Shinhan Việt Nam"
    },
    {
        id: "211",
        img: tpbank,
        name: "Ngân hàng Tiên Phong"
    },
    {
        id: "212",
        img: vib,
        name: "NH TMCP Quốc tế Việt Nam"
    },
    {
        id: "213",
        img: vpbank,
        name: "Việt Nam Thịnh Vượng"
    },
    {
        id: "214",
        img: vietcap,
        name: "Ngân hàng Bản Việt"
    },
    {
        id: "215",
        img: wooribank,
        name: "Ngân hàng Woori Bank tại Việt Nam"
    },
    {
        id: "216",
        img: bidv,
        name: "Đầu tư và Phát triển Việt Nam"
    },
    {
        id: "338",
        img: sacombank,
        name: "Sài Gòn Thương Tín"
    },
    {
        id: "385",
        img: vietabank,
        name: "Ngân hàng TMCP Việt Á"
    },
] 

export const dataBank2 = [
    {
        id: "183",
        img: vietcombank,
        name: "Vietcombank"
    },
    {
        id: "184",
        img: agribank,
        name: "Agribank"
    },
    {
        id: "185",
        img: vietinbank,
        name: "VietinBank"
    },
    {
        id: "186",
        img: mbbank,
        name: "MBbank"
    },
    {
        id: "187",
        img: techcombank,
        name: "Techcombank"
    },
    {
        id: "188",
        img: abbank,
        name: "ABbank"
    },
    {
        id: "189",
        img: acb,
        name: "ACB"
    },
    {
        id: "190",
        img: anz,
        name: "ANZ"
    },
    {
        id: "191",
        img: bacabank,
        name: "BacAbank"
    },
    {
        id: "192",
        img: baovietbank,
        name: "Baovietbank"
    },
    {
        id: "193",
        img: cake,
        name: ""
    },
    {
        id: "194",
        img: donga,
        name: "DongAbank"
    },
    {
        id: "195",
        img: eximbank,
        name: "Eximbank"
    },

    {
        id: "196",
        img: hdbank,
        name: "Hdbank"
    },
    {
        id: "197",
        img: hsbc,
        name: "HSBC"
    },
    {
        id: "198",
        img: kienlong,
        name: "Kiên Long Bank"
    },
    {
        id: "199",
        img: lienviet,
        name: "Bưu điện Liên Việt"
    },
    {
        id: "200",
        img: msb,
        name: "MSB"
    },
    {
        id: "201",
        img: ncb,
        name: "NCB"
    },
    {
        id: "202",
        img: namabank,
        name: "NamAbank"
    },
    {
        id: "203",
        img: ocb,
        name: "OCB"
    },
    {
        id: "204",
        img: oceanbank,
        name: "Oceanbank"
    },
    {
        id: "205",
        img: pvcombank,
        name: "Pvcombank"
    },
    {
        id: "206",
        img: scb,
        name: "Sài Gòn Bank"
    },

    {
        id: "207",
        img: shb,
        name: "SHB"
    },
    {
        id: "208",
        img: saigonbank,
        name: "Saigonbank"
    },
    {
        id: "209",
        img: seabank,
        name: "Seabank"
    },
    {
        id: "210",
        img: shinhan,
        name: "Shinhan Bank"
    },
    {
        id: "211",
        img: tpbank,
        name: "TPbank"
    },
    {
        id: "212",
        img: vib,
        name: "VIB"
    },
    {
        id: "213",
        img: vpbank,
        name: "VPbank"
    },
    {
        id: "214",
        img: vietcap,
        name: "Ngân hàng Bản Việt"
    },
    {
        id: "215",
        img: wooribank,
        name: "Wooribank"
    },
    {
        id: "216",
        img: bidv,
        name: "BIDV"
    },
    {
        id: "338",
        img: sacombank,
        name: "Sacombank"
    },
    {
        id: "385",
        img: vietabank,
        name: "Vietabank"
    },
] 