import scb from '../../../assets/image/scb/demo.png'
import { formatMoney, upperCase, convertDateToString } from '../../../utils/utils'

const Sacombank = (props: any) => {


  return (
    <div className="scb">
      <div className="scb__image">
        <img src={scb} alt="" />
      </div>
      <div className="scb__form">

        <div className='time'>{props.data.time}</div>

        <div className='scb__form__bill'>
          <div className='name_person_account'>{props.data.number_account} ({upperCase(props.data.name_account)})</div>
          <div className='money'>-{formatMoney(props.data.money, 0, 0)} VND</div>
          <div className='date'>{props.data.time}:25 {convertDateToString(props.data.date)}</div>
  
        </div>
        <div className='trading_code'>{props.data.trading_code}</div>
        <div className='name_person_account2'>{props.data.number_person_account}</div>
        <div className='name_account'>{props.data.number_account}</div>
        <div className='tag_bank'>{props.data.bank}</div>
        <div className='content'>{props.data.content}</div>
        <div className='money2'>{formatMoney(props.data.money, 0, 0)}</div>
        <div className='free'>0</div>
        <div className='free2'>{formatMoney(props.data.money, 0, 0)}</div>
      </div>
    </div>
  )
}

export default Sacombank
