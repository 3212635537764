
import title from '../../../assets/image/tpbank/title.png'
import secuss from '../../../assets/image/tpbank/secuss.png'
import footer from '../../../assets/image/tpbank/footer.png'
import tick from '../../../assets/image/tpbank/tick.png'
import icon from '../../../assets/image/tpbank/icon.jpg'
import mui from '../../../assets/image/tpbank/mui.png'
import hour from '../../../assets/image/tpbank/hour.png'
import {
  formatMoney,
  upperCase,
  convertDateToString,
  dataBank
} from '../../../utils/utils'

const TPbank = (props: any) => {

  let logo = dataBank.find(function (element) {
    return element.id === props.data.bank_logo;
  });

  return (
    <div className="tpbank">
      <div className="b-header">
        <div className="b-header__time" style={{ backgroundColor: "#f6f6fe", paddingLeft: '15px', fontSize: "14px", color: "#fff" }}>{props.data.time}</div>
        <div><img src={hour} alt="" /></div>
      </div>
      <div className="tpbank__icon-home">
        {' '}
        <img src={title} alt="" />
      </div>
      <div className="tpbank-bgr">

        <div className="tpbank__bank_success">
          <img style={{ width: "180px", marginTop: "10px" }} src={secuss} alt="" />
          <div className="tpbank__bank_success__title">
            Giao Dịch Thành Công
          </div>
          <div className="tpbank__bank_success__money">
            {formatMoney(props.data.money, 0, 0)} VND
          </div>
        </div>
        <div className="tpbank__info_bank">
          <div className="tpbank__info_bank_item">
            <div className="tpbank__info_bank_item__img">
              <img src={icon} alt="" />
            </div>
            <div className="tpbank__info_bank_item__content">
              <div>{upperCase(props.data.name_person_account)}</div>
              <div>{props.data.number_person_account}</div>
            </div>
            <div className="tpbank__info_bank_item__icon">
              <img src={tick} alt="" />
            </div>
          </div>
          <div className='mui_ten'>
            <img style={{ width: "22px", height: "20px", marginLeft: "6px" }} src={mui} alt="" />
            <span></span>
          </div>
          <div className="tpbank__info_bank_item2">
            <div className="tpbank__info_bank_item2__img">
              <img src={logo?.img} alt="" />
            </div>
            <div className="tpbank__info_bank_item2__content">
              <div>{upperCase(props.data.name_account)} <span className="tpbank__info_bank_item2__save">
                Đã lưu
              </span>
              </div>
              <div>{props.data.number_account}</div>
            </div>

          </div>
        </div>

        <div className="b-body">
          <div className="tpbank_item">
            <div>Mã giao dịch</div>
            <div>{props.data.trading_code} </div>
          </div>
          <div className="tpbank_item">
            <div>Nội dung</div>
            <div>{props.data.content}</div>
          </div>
          <div className="tpbank_item">
            <div>Thời gian</div>
            <div>
              {props.data.time}:00, Ngày {convertDateToString(props.data.date)}{' '}
            </div>
          </div>

          <div className="tpbank_item">
            <div>Cách thức</div>
            <div>Chuyển nhanh Napas 247</div>
          </div>
        </div>
      </div>
      <div className="b-footer">
        <img src={footer} alt="" />
      </div>

    </div>
  )
}

export default TPbank
