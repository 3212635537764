import img3 from '../../../assets/image/tcb/h-time.png'
import img1 from '../../../assets/image/tcb/bg.png'
import img2 from '../../../assets/image/tcb/footer.png'
import img4 from '../../../assets/image/tcb/head3.png'

import {
  formatMoney,
  upperCase} from '../../../utils/utils'

const TCB = (props: any) => {
  return (
    <div
      className="tcb"
      style={{
        background: `url('${img1}') 0 0 no-repeat`,
        backgroundSize: '340px 700px',
        width: 340,
        height: 750
      }}
    >
      <div
        className="t-head"
        style={{
          background: `url('${img3}')`,
          backgroundSize: '123px 53px'
        }}
      >
        <div className="t-time">
          {props.data.time?.length === 4
            ? `0${props.data.time}`
            : props.data.time}
        </div>
      </div>

      <div className="info">
        <img
          className="bg-img"
          src={img4}
          alt=""
          style={{
            width: 340
          }}
        />
        <div
          className="t-col"
          style={{
            padding: '0 20px'
          }}
        >
          <div
            className="i-var"
            style={{
              fontSize: '24px'
            }}
          >
            {`tới ${upperCase(props.data.name_account)}`}
          </div>
          <div
            className="i-var"
            style={{
              fontSize: '24px'
            }}
          >
            {`VND`}
          </div>
        </div>
        <div className="t-col">
          <div
            className="i-var"
            style={{
              fontSize: '24px'
            }}
          >
            {formatMoney(props.data.money, 0, 0)}
          </div>
        </div>
        <div className="t-col">
          <div className="i-title">Thông tin chi tiết</div>
          <div className="i-var">{props.data.bank}</div>
          <div className="i-var">{props.data.number_account}</div>
        </div>
        <div className="t-col">
          <div className="i-title">Lời nhắn</div>
          <div className="i-title">{props.data.content}</div>
        </div>
        <div className="t-col">
          <div className="i-title">Ngày thực hiện</div>
          <div className="i-var">{`${
            new Date(props.data.date)?.getDay() < 10 ? '0' : ''
          }${new Date(props.data.date)?.getDay()} tháng ${new Date(
            props.data.date
          )?.getMonth()}, ${new Date(props.data.date)?.getFullYear()}`}</div>
        </div>
        <div className="t-col">
          <div className="i-title">Mã giao dịch</div>
          <div className="i-var">{props.data.trading_code}</div>
        </div>
      </div>
      <div className="foot-img">
        <img src={img2} alt="" className="t-f-img" />
      </div>
    </div>
  )
}

export default TCB
