
import header from '../../../assets/image/bidv/header.png'
import footer from '../../../assets/image/bidv/footer1.png'
import { formatMoney, upperCase, convertDateToString } from '../../../utils/utils'

const BIDV = (props: any) => {


  return (
    <div className="bidv">
      <div className='bidv__header'>
        <div>
          <div className="bidv__header__time"><span>{props.data.time}</span></div>
          <img src={header} alt="" />
        </div>
      </div>


      <div className='bidv__body'>


        <div className='bidv__body__transfer'>
          Quý khách đã chuyển thành công số tiền  <span>{formatMoney(props.data.money, 0, 0)} VND </span>
          đến số tài khoản <span>{props.data.number_account}/ {upperCase(props.data.name_account)}/ {props.data.bank} </span>
          vào lúc {convertDateToString(props.data.date)} {props.data.time}:00. Nội dung: {props.data.content}

        </div>
        <div className='bidv__body__border'>

        </div>
        <div className='bidv__body__tracking'>
        Số tham chiếu: <b>{props.data.trading_code}</b>
        </div>
      </div>

      <img src={footer} alt="" />
    </div>
  )
}

export default BIDV
