import msb from '../../../assets/image/msb/demo.png'
import { formatMoney, upperCase, convertDateToString } from '../../../utils/utils'

const MSB = (props: any) => {


  return (
    <div className="msb">
      <div className="msb__image">
        <img src={msb} alt="" />
      </div>
      <div className="msb__form">
          <div className='time'>{props.data.time}</div>
          <div className='name_person_account'>{upperCase(props.data.name_person_account)}</div>
          <div className='money'>{formatMoney(props.data.money, 0, 0)} VND</div>
          <div className='tag_bank'>{props.data.number_account}</div>
          <div className='name_account'>{upperCase(props.data.name_account)}</div>
          <div className='content'>{props.data.content}</div>
          <div className='date'>{props.data.time} {convertDateToString(props.data.date)}</div>
      </div>
    </div>
  )
}

export default MSB
