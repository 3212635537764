import img1 from '../../../assets/image/vcb/1.png'
import img2 from '../../../assets/image/vcb/2.png'
import img4 from '../../../assets/image/vcb/4.png'
import ok from '../../../assets/image/ok.png'
import vcb from '../../../assets/image/vcb/demo.png'
import { formatMoney, upperCase, LayThu, convertDateToString } from '../../../utils/utils'

const VCB = (props: any) => {


  return (
    <div>
      {props?.data.type_file === "1" ?
        <div className="vcb">
          <div className="b-header">
            <div className='b-header__time'>{props.data.time}</div>
            <div className='b-header__pin'><img src={img1} alt="" /></div>
          </div>
          <div className='vcb__icon-home'>  <img src={img4} alt="" /></div>
          <img src={img2} alt="" />
          <div className='vcb__bank_success'>
            <div className='vcb__bank_success__title'>CHUYỂN KHOẢN THÀNH CÔNG</div>
            <div className='vcb__bank_success__money'>{formatMoney(props.data.money, 0, 0)} VND</div>
            <div className='vcb__bank_success__time'>{props.data.time} {LayThu(props.data.date)} {convertDateToString(props.data.date)}</div>
          </div>
          <div className="vcb-bgr">
            <div className="b-body">
              <div className="vcb_item">
                <div>Tên người thụ hưởng</div>
                <div>{upperCase(props.data.name_account)}</div>
              </div>
              <div className="vcb_item">
                <div>Tài khoản thụ hưởng</div>
                <div>{props.data.number_account}</div>
              </div>
              <div className="vcb_item">
                <div>Ngân hàng thụ hưởng</div>
                <div>{props.data.bank}</div>
              </div>
              <div className="vcb_item">
                <div>Mã giao dịch</div>
                <div>{props.data.trading_code} </div>
              </div>
              <div className="vcb_item">
                <div>Nội dung</div>
                <div>{props.data.content}</div>
              </div>
              <div className="vcb_item">
                <div>Lưu mẫu chuyển tiền</div>
                <div> <img style={{ width: "40px" }} src={ok} alt="" /></div>
              </div>
            </div>
            <div className="b-footer">
              <div className="btn-vcb">Thực hiện giao dịch mới</div>
              <div className='btn-crollbar'></div>
            </div>
          </div>
        </div>
        :
        <div className="vcb1">
          <div className="vcb1__image">
            <img src={vcb} alt="" />
          </div>
          <div className="vcb1__form">

            <div className='time'>{props.data.time}</div>

            <div className='money'>{formatMoney(props.data.money, 0, 0)} VND</div>
            <div className='date'>{props.data.time} {LayThu(props.data.date)} {convertDateToString(props.data.date)}</div>

            <div className='name_person_account2'>{upperCase(props.data.name_account)}</div>
            <div className='name_account'>{props.data.number_account}</div>
            <div className='tag_bank'>{props.data.bank}</div>


            <div className='trading_code'>{props.data.trading_code}</div>
            <div className='content'>{props.data.content}</div>

          </div>
        </div>

      }</div>
  )
}

export default VCB
