import header from '../../../assets/image/vpbank/header.png'
import footer from '../../../assets/image/vpbank/footer.png'
import border from '../../../assets/image/vpbank/border.png'
import logo1 from '../../../assets/image/vpbank/logo.png'

import { formatMoney3, upperCase, convertDateToString, dataBank } from '../../../utils/utils'

const VPBank = (props: any) => {
  let logo = dataBank.find(function (element) {
    return element.id === props.data.bank_logo;
  });

  return (
    <div className="vpbank">
      <div className='vpbank__header'>
        <div>
          <div className="vpbank__header__time"><span>{props.data.time}</span></div>
          <img src={header} alt="" />
        </div>
      </div>
      <div className='vpbank__header2__money1'> {formatMoney3(props.data.money, 0, 0)} <span className='text_underline'>đ</span></div>
        <div className='vpbank__header2__money2'> {
          props.data.money_text +
          ' đồng'
        }</div>
      <div className='vpbank__header2'>
        
        <img style={{borderRadius: "8px"}}  src={logo1} alt="" />
        <div className='vpbank__header2__date_start1'>
          <div>Thông tin người nhận</div>
        </div>
        <div className="body">
          {/** day prop icon theo ngan hang thu huong */}
          <div className="icon">
            <img
              src={logo?.img}
              alt=""
              style={{
                width: '42px',
                boxShadow: '',
                borderRadius: '40px'
              }}
            />
          </div>
          <div className="account">
            <div className="name_account">
              {upperCase(props.data.name_account)}
            </div>
            <div className="font-x">{props.data.number_account}</div>
          </div>
        </div>
        <img src={border} alt="" />
      </div>

      <div className='vpbank__body'>
       
        <div className='vpbank__body__item'>
          <div>Thời gian</div>
          <div>{convertDateToString(props.data.date)}</div>
        </div>
        <div className='vpbank__body__item'>
          <div>Nội dung</div>
          <div>{props.data.content}</div>
        </div>
        <div  className='vpbank__body__item'>
          <div>Phương thức</div>
          <div>Chuyển nhanh Napas 247</div>
        </div>
        <div className='vpbank__body__item'>
          <div>Phí</div>
          <div> 0 <span className='text_underline'>đ</span></div>
        </div>

      </div>
      <img src={footer} alt="" />
    </div>
  )
}
export default VPBank
