import { Link, Outlet } from 'react-router-dom'
import { invoices } from '../data/invoices'
import FormInfo from './components/FormInfo'

const Invoices = () => {
  return (
    <main>
      <FormInfo></FormInfo>
    </main>
  )
}

export default Invoices
